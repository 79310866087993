
import LoadingMask from "@/components/common/LoadingMask.vue";
import { LoadingMaskProps } from "@/types/loadingMask";
import { BillingAccount, WebeyezObject } from "@/types/localtypes";
import { JPCInstance } from "@jfrog-ba/myjfrog-common";
import { Component, Inject, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "TabBillingDetails",
  components: { LoadingMask },
})
export default class TabBillingDetails extends Vue {
  @Inject() readonly globalBus!: Vue;
  @Prop() private billingAccount!: BillingAccount;
  paymentObjectWrapperId = "payment-object-wrapper-update-billing";
  isLoading: boolean = true;
  jpcLoaded = false;
  errorRecentlyReceived = false;

  get loadingMaskProps(): LoadingMaskProps {
    return {
      loading: this.isLoading,
    };
  }
  setLoading(loading: boolean) {
    this.isLoading = loading;
    this.$emit("onLoadingChange", loading);
  }
  async mounted() {
    this.setLoading(true);
    // @ts-ignore
    if (window.JPC) {
      this.jpcLoaded = true;
    } else {
      try {
        const paymentScript = await this.$jfPayments.fetchPaymentsScripts();
        if (paymentScript) {
          eval(paymentScript);
          this.jpcLoaded = true;
        }
      } catch (e) {
        this.$log.error(e);
        this.notifyError(this.$jfMessages.jpay_loading_failed);
      }
    }
    if (this.jpcLoaded) {
      this.loadBillingDetails();
    } else {
      this.setLoading(false);
    }
  }

  loadBillingDetails() {
    // @ts-ignore
    const jpc: JPCInstance = new JPC(
      this.getJPCPrepopulate(),
      this.getJPCMessages(),
      this.getJPCOptions(),
      this.loadJPCCallbacks(),
    );
    jpc.loadUpdateBillingCustomerDetailsForm(this.billingAccount.paymentAccountNumber);
  }

  getJPCOptions(): JPCInstance["options"] {
    return {
      divJpcFormId: this.paymentObjectWrapperId,
      placeHoldersActive: false,
      appSessionId: this.$jfUsers.getSessionId(),
    };
  }

  getJPCPrepopulate(): JPCInstance["prepopulate"] {
    return {};
  }

  getJPCMessages(): JPCInstance["messages"] {
    return { btn_updateSubmit: "Submit" };
  }

  loadJPCCallbacks(): JPCInstance["callbacks"] {
    return {
      updateBillingCustomerDetailsForm_render(html) {
        const inputs = html.querySelectorAll("input");
        for (let i = 0; i < inputs.length; i++) {
          inputs[i].classList.add("form-control");
        }
        return html;
      },
      updateBillingCustomerDetailsForm_submitSuccess: (data: {}) => {
        // this.$log.info("updateBillingCustomerDetailsForm_submitSuccess", data);
        this.$jfNotification.success({ text: this.$jfMessages.billing_payments_details_update_success });
        this.$jfWebeyez.send({
          goal_key: "edit_billing_details",
          isSuccess: true,
        });
        this.setLoading(false);
      },
      updateBillingCustomerDetailsForm_loadSuccess: () => {
        this.setLoading(false);
      },
      updateBillingCustomerDetailsForm_loadError: (status: number, errorMessage: string) => {
        const jpcErrorMessage = `[${this.billingAccount.paymentAccountNumber}] - Error : ${status}${
          errorMessage ? " => " + errorMessage : ""
        }`;
        this.$log.error(jpcErrorMessage);
        // this.$log.info(`updateBillingCustomerDetailsForm_loadError ${jpcErrorMessage}`);
        this.setLoading(false);
        if (status === 401) {
          this.$jfUsers.logout(true);
          return;
        }
        this.notifyError(this.$jfMessages.jpay_billing_details_form_loading_failed);
      },
      updateBillingCustomerDetailsForm_submitError: (status: number, errorMessage: string) => {
        // this.$log.info("updateBillingCustomerDetailsForm_submitError status=" + status + " / error = " + errorMessage);
        const jpcErrorMessage = `[${this.billingAccount.paymentAccountNumber}] - Error : ${status}${
          errorMessage ? " => " + errorMessage : ""
        }`;
        this.$log.error(jpcErrorMessage);
        this.setLoading(false);
        if (status === 401) {
          this.$jfUsers.logout(true);
          return;
        }
        this.notifyError(this.$jfMessages.jpay_billing_details_form_submit_failed);
      },
      updateBillingCustomerDetailsForm_beforeSubmit: () => {
        // this.$log.info("updateBillingCustomerDetailsForm_beforeSubmit");
        this.setLoading(true);
        return true;
      },
      updateBillingCustomerDetailsForm_beforeCheckForm: () => {
        // this.$log.info("updateBillingCustomerDetailsForm_beforeCheckForm");
        return true;
      },

      global_selectStateChanged: (select: string) => {
        // this.$log.info("global_selectStateChanged", select);
      },
      global_selectCountryChanged: (select: string) => {
        // this.$log.info("global_selectCountryChanged", select);
      },
    };
  }

  notifyError(errorMessage: string) {
    this.globalBus.$emit("notifyError", errorMessage);
    this.$jfWebeyez.send({ goal_key: "edit_billing_details", isSuccess: false, errorMessage: errorMessage });
  }
}
