
import LoadingMask from "@/components/common/LoadingMask.vue";
import PulseSpinner from "@/components/spinners/PulseSpinner.vue";
import { LoadingMaskProps } from "@/types/loadingMask";
import { BillingAccount } from "@/types/localtypes";
import { PulseSpinnerProperties } from "@/types/spinners";
import { InvoiceDTO } from "@jfrog-ba/myjfrog-common";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "BillingAccountLastPayment",
  components: { LoadingMask, PulseSpinner },
})
export default class BillingAccountLastPayment extends Vue {
  @Prop() private billingAccount!: BillingAccount;
  @Prop() private isPrepaidPayment!: boolean;
  @Prop() private isMonthlyPayment!: boolean;
  @Prop() private eventBus!: Vue;

  hasError: boolean = false;
  invoice: InvoiceDTO | null = null;
  detailSpinnerProps: PulseSpinnerProperties = {
    size: "xxs",
  };

  isLoading: boolean = true;
  creditCardIsLoading: boolean = true;
  creditCard: string = "";
  get loadingMaskProps(): LoadingMaskProps {
    return {
      loading: this.isLoading,
    };
  }

  get invoiceDate() {
    if (this.invoice) {
      return this.$dateUtils.format(this.invoice.invoiceDate, "MMMM Do, YYYY");
    }
    return "";
  }

  get formatedTotalAmount() {
    return this.invoice && this.$utils.formatPrice(this.invoice.invoiceTotalAmount);
  }

  async fetchCreditCard() {
    if (this.isMonthlyPayment) {
      this.creditCardIsLoading = true;

      try {
        const creditCardInfoResponse = await this.$jfBillingAccounts.getCreditCardInfo(
          this.billingAccount.paymentAccountNumber,
        );

        // add space after each group of 4 stars
        this.creditCard = creditCardInfoResponse.cardNumber
          ? creditCardInfoResponse.cardNumber.replace(/(\*{4})/g, fourStarsGroup => {
              return fourStarsGroup + " ";
            })
          : "N/A";

        this.creditCardIsLoading = false;
      } catch (error) {
        this.$log.error(error);
        this.creditCard = "Error...";
        this.creditCardIsLoading = false;
        this.$jfNotification.error({ text: this.$jfMessages.billing_fetch_credit_card_fingerprint_failed });
      }
    } else {
      this.creditCard = "";
    }
  }

  onLoadingInvoices() {
    this.invoice = null;
    this.hasError = false;
    this.isLoading = true;
  }
  onNewInvoicesLoaded(invoices: InvoiceDTO[], paymentAccountNumber: BillingAccount["paymentAccountNumber"]) {
    if (this.billingAccount && paymentAccountNumber === this.billingAccount.paymentAccountNumber) {
      this.isLoading = false;
      if (invoices.length > 0) {
        this.invoice = invoices[invoices.length - 1];
        this.fetchCreditCard();
      }
    }
  }
  onErrorWhileLoadingInvoices(paymentAccountNumber: BillingAccount["paymentAccountNumber"]) {
    if (this.billingAccount && paymentAccountNumber === this.billingAccount.paymentAccountNumber) {
      this.invoice = null;
      this.isLoading = false;
      this.hasError = true;
    }
  }

  busEvents: { [key: string]: (...params: any[]) => any } = {
    loadingInvoices: this.onLoadingInvoices,
    newInvoicesLoaded: this.onNewInvoicesLoaded,
    errorWhileLoadingInvoices: this.onErrorWhileLoadingInvoices,
  };

  created() {
    for (let busEventsKey in this.busEvents) {
      this.eventBus.$on(busEventsKey, this.busEvents[busEventsKey]);
    }
  }

  beforeDestroy() {
    for (let busEventsKey in this.busEvents) {
      this.eventBus.$off(busEventsKey, this.busEvents[busEventsKey]);
    }
  }
}
