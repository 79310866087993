
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import LoadingMask from "@/components/common/LoadingMask.vue";
import { LoadingMaskProps } from "@/types/loadingMask";
import { InvoiceDTO, SubscriptionDTO } from "@jfrog-ba/myjfrog-common";
import { JFGrid } from "jfrog-ui-vue-essentials";
import * as fileSaver from "file-saver";
import { BillingAccount } from "@/types/localtypes";

interface ZuoraModel {
  zuoraInvoiceSubscriptionsModel: ZuoraInvoiceSubscriptionsModel;
}

interface ZuoraInvoiceSubscriptionsModel {
  zuoraSubscriptionModel: ZuoraSubscriptionModel[];
}

interface ZuoraSubscriptionModel {
  invoiceItems: InvoiceItemModel[];
}

interface InvoiceItemModel {
  chargeAmount: String;
  chargeName: String;
  productName: String;
  quantity: String;
  unitOfMeasure: String;
  taxAmount: String;
}

@Component({
  name: "ModalInvoicesHistory",
  components: { LoadingMask, JFGrid },
})
export default class ModalInvoicesHistory extends Vue {
  @Prop() private invoices!: InvoiceDTO[];
  @Prop() private paymentAccountNumber!: BillingAccount["paymentAccountNumber"];
  @Prop() private subscriptions!: SubscriptionDTO[];
  @Inject() readonly globalBus!: Vue;
  isLoading: boolean = false;
  gridProps: any = {
    theme: "light",
    displayToolbar: false,
    columns: [
      {
        label: "Number",
        field: "invoiceNumber",
        sortable: false,
        flexGrow: 1,
      },
      {
        label: "Date",
        field: "invoiceDate",
        sortable: false,
        flexGrow: 1,
        converter: (val: number) => this.$dateUtils.format(val, "MMMM Do, YYYY"),
        tooltipConverter: (val: number) => this.$dateUtils.format(val, "MMMM Do, YYYY"),
      },
      ...(this.hasEnterprisePlusUsageInvoice
        ? [
            {
              label: "Data Consumption (TB)",
              field: "enterprisePlusUsage",
              sortable: false,
              flexGrow: 1,
              converter: (val: number, row: ZuoraModel & InvoiceDTO) => {
                const isNotEPlus = this.isNotEPlusProduct(row);
                const value = this.$utils.round(val, 2);
                if (isNotEPlus) {
                  return "N/A";
                }

                return value;
              },
            },
          ]
        : []),
      ...(this.hasDataTransferOrUsageInInvoice
        ? [
            {
              label: "Data Transfer (GB)",
              field: "dataTransferUsage",
              sortable: false,
              flexGrow: 1,
              converter: (val: number, row: ZuoraModel & InvoiceDTO) => {
                const isEPlus = this.isEPlusProduct(row);
                const value = this.$utils.round(val, 2);
                if (isEPlus && (!value || value === "0.00")) {
                  return "N/A";
                }

                return value;
              },
            },
          ]
        : []),
      ...(this.hasDataTransferOrUsageInInvoice
        ? [
            {
              label: "Storage (GB)",
              field: "storageUsage",
              sortable: false,
              flexGrow: 1,
              converter: (val: number, row: ZuoraModel & InvoiceDTO) => {
                const isEPlus = this.isEPlusProduct(row);
                const value = this.$utils.round(val, 2);
                if (isEPlus && (!value || value === "0.00")) {
                  return "N/A";
                }

                return value;
              },
            },
          ]
        : []),
      ...(this.hasPipelinesInInvoice || this.hasEnterprisePlusUsageInvoice
        ? [
            {
              label: "Pipelines (Min)",
              field: "pipelinesUsage",
              sortable: false,
              flexGrow: 1,
              converter: (val: number) => {
                if (val === 0) {
                  return 0;
                }
                if (!val) {
                  return "N/A";
                }
                return this.$utils.round(val, 0);
              },
            },
          ]
        : []),
      {
        label: "Total",
        field: "invoiceTotalAmount",
        sortable: false,
        flexGrow: 1,
        converter: (val: number) => {
          const formatedVal = this.$utils.formatPrice(val);
          return "$" + formatedVal;
        },
      },
      {
        label: "Document",
        field: "status",
        sortable: false,
        flexGrow: 0.5,
        onClick: this.downloadInvoice,
        tooltipConverter: () => (this.nbInvoices > 1 ? "Download Document" : ""),
        getHtml: (val: string, row: InvoiceDTO) => {
          return `<div pointer mjf-green><i class="icon-pdf" data-gtm-event="modal-billing-history|download-link"></i></div>`;
        },
      },
    ],
    state: {
      page: 1,
      orderBy: "invoiceDate",
      order: "desc",
    },
    config: {
      fetchDataPromise: this.fetchDataPromise,
      noPagination: true,
      mode: "client",
      onFilter: () => true,
    },
  };

  isEPlusProduct(row: ZuoraModel & InvoiceDTO): boolean {
    const model = row.zuoraInvoiceSubscriptionsModel;
    if (
      !model ||
      !model.zuoraSubscriptionModel ||
      model.zuoraSubscriptionModel.length === 0 ||
      !model.zuoraSubscriptionModel[0].invoiceItems ||
      model.zuoraSubscriptionModel[0].invoiceItems.length === 0
    ) {
      return this.hasEnterprisePlusUsageInvoice;
    }

    return model.zuoraSubscriptionModel.some(invoice => {
      return invoice.invoiceItems.some(
        (invoice: InvoiceItemModel) =>
          invoice.productName === "Cloud Enterprise E+" || invoice.productName === "Cloud Enterprise E+ (Non Active)",
      );
    });
  }

  isNotEPlusProduct(row: ZuoraModel & InvoiceDTO): boolean {
    const model = row.zuoraInvoiceSubscriptionsModel;

    if (
      !model ||
      !model.zuoraSubscriptionModel ||
      model.zuoraSubscriptionModel.length === 0 ||
      !model.zuoraSubscriptionModel[0].invoiceItems ||
      model.zuoraSubscriptionModel[0].invoiceItems.length === 0
    ) {
      return !this.hasEnterprisePlusUsageInvoice;
    }
    return model.zuoraSubscriptionModel.every(invoice =>
      invoice.invoiceItems.every(
        (invoice: InvoiceItemModel) =>
          invoice.productName !== "Cloud Enterprise E+" && invoice.productName !== "Cloud Enterprise E+ (Non Active)",
      ),
    );
  }

  fetchDataPromise() {
    return Promise.resolve({ data: this.invoices, total: this.invoices.length });
  }

  get hasPipelinesInInvoice() {
    return this.subscriptions.some(subscription => subscription.meta.hasPipelines);
  }

  get hasDataTransferOrUsageInInvoice() {
    return (
      this.invoices.some(invoice => invoice.dataTransferUsage || invoice.storageUsage) ||
      !this.hasEnterprisePlusUsageInvoice
    );
  }

  get hasEnterprisePlusUsageInvoice() {
    let subscription = this.subscriptions.filter(sub => sub.paymentAccountNumber === this.paymentAccountNumber)[0];
    return subscription.paymentType === "ENTERPRISE_PLUS" || subscription.paymentType === "ENTERPRISE_PLUS_HYBRID";
  }

  get loadingMaskProps(): LoadingMaskProps {
    return {
      loading: this.isLoading,
    };
  }

  get nbInvoices(): number {
    return this.invoices ? this.invoices.length : 0;
  }

  async downloadInvoice(invoiceToDownload: InvoiceDTO) {
    this.isLoading = true;

    let invoice;
    try {
      invoice = await this.$jfBillingAccounts.getInvoice(
        this.paymentAccountNumber,
        invoiceToDownload.invoiceFileKey,
        invoiceToDownload.invoiceNumber,
      );
      this.$jfWebeyez.send({ goal_key: "download_billing_history", isSuccess: true });
    } catch (e) {
      this.globalBus.$emit("notifyError", this.$jfMessages.billing_download_failed);
      this.$jfWebeyez.send({
        goal_key: "download_billing_history",
        isSuccess: false,
        errorMessage: this.$jfMessages.billing_download_failed,
      });
      return;
    } finally {
      this.isLoading = false;
    }

    fileSaver.saveAs(new Blob([invoice]), `${invoiceToDownload.invoiceNumber}.PDF`);
  }

  dismiss() {
    this.$jfModal.dismiss();
  }

  mounted() {
    // should be called in each custom modal in his mounted() method (it will trigger resizes and other global stuff).
    // Called automatically by Essentials in JS but doesn't work in TS components so we should trigger it manually.
    this.$jfModal.childComponentIsSet();
  }
}
