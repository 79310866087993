
import LoadingMask from "@/components/common/LoadingMask.vue";
import PreviousInvoicesGraph from "@/components/views/billingAccounts/graphs/PreviousInvoicesGraph.vue";
import { LoadingMaskProps } from "@/types/loadingMask";
import { BillingAccount } from "@/types/localtypes";
import { InvoiceDTO } from "@jfrog-ba/myjfrog-common";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "BillingAccountPreviousPayments",
  components: { LoadingMask, PreviousInvoicesGraph },
})
export default class BillingAccountPreviousPayments extends Vue {
  @Prop() private eventBus!: Vue;
  @Prop() private billingAccount!: BillingAccount;
  invoices: InvoiceDTO[] | null = null;
  isLoading: boolean = true;
  get loadingMaskProps(): LoadingMaskProps {
    return {
      loading: this.isLoading,
    };
  }

  onLoadingInvoices() {
    this.isLoading = true;
    this.invoices = null;
  }
  onNewInvoicesLoaded(invoices: InvoiceDTO[], paymentAccountNumber: BillingAccount["paymentAccountNumber"]) {
    if (this.billingAccount && paymentAccountNumber === this.billingAccount.paymentAccountNumber) {
      this.invoices = invoices;
      this.isLoading = false;
    }
  }

  onErrorWhileLoadingInvoices(paymentAccountNumber: BillingAccount["paymentAccountNumber"]) {
    if (this.billingAccount && paymentAccountNumber === this.billingAccount.paymentAccountNumber) {
      this.invoices = null;
      this.isLoading = false;
    }
  }
  busEvents: { [key: string]: (...params: any[]) => any } = {
    loadingInvoices: this.onLoadingInvoices,
    newInvoicesLoaded: this.onNewInvoicesLoaded,
    errorWhileLoadingInvoices: this.onErrorWhileLoadingInvoices,
  };

  created() {
    for (let busEventsKey in this.busEvents) {
      this.eventBus.$on(busEventsKey, this.busEvents[busEventsKey]);
    }
  }

  beforeDestroy() {
    for (let busEventsKey in this.busEvents) {
      this.eventBus.$off(busEventsKey, this.busEvents[busEventsKey]);
    }
  }
}
