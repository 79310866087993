import myJFrogUtils from "@/util/myJFrogUtils";

export default {
  responsive: true,
  maintainAspectRatio: false,
  legend: { display: false },
  scales: {
    yAxes: [
      {
        type: "linear",
        ticks: {
          beginAtZero: true,
          userCallback: function(tick: any): any {
            return "$ " + myJFrogUtils.formatPrice(tick);
          },
        },
        gridLines: {
          display: true,
          borderDash: [10, 10],
        },
      },
    ],
    xAxes: [
      {
        barPercentage: 0.5,
        scaleLabel: {
          display: false,
        },
        gridLines: {
          display: false,
        },
      },
    ],
  },

  tooltips: {
    callbacks: {
      label: function(tooltipItem: any, data: any) {
        let label = data.datasets[tooltipItem.datasetIndex].label || "";
        if (label) {
          label += ": ";
        }
        label += "$ " + myJFrogUtils.formatPrice(tooltipItem.yLabel);
        return label;
      },
    },
  },
};
