
import LoadingMask from "@/components/common/LoadingMask.vue";
import Tab from "@/components/common/Tab.vue";
import Tabs from "@/components/common/Tabs.vue";
import TabBillingDetails from "@/components/views/billingAccounts/modals/tabs/TabBillingDetails.vue";
import TabCCDetails from "@/components/views/billingAccounts/modals/tabs/TabCCDetails.vue";
import TabCustomerDetails from "@/components/views/billingAccounts/modals/tabs/TabCustomerDetails.vue";
import { LoadingMaskProps } from "@/types/loadingMask";
import {
  AnyMethod,
  BillingAccount,
  CreditCardFormStep,
  CreditCardFormVirtualStep,
  ModalAccountDetailsTabCode,
} from "@/types/localtypes";
import { BillingAccountInformation } from "@jfrog-ba/myjfrog-common";
import { Component, Prop, Vue } from "vue-property-decorator";

type TabId = "_tabCustomerDetails" | "_tabBillingDetails" | "_tabCreditCard";
type ModalBillingDetailsAction = null | AnyMethod;

@Component({
  name: "ModalBillingDetails",
  components: { LoadingMask, Tabs, Tab, TabCustomerDetails, TabBillingDetails, TabCCDetails },
})
export default class ModalBillingDetails extends Vue {
  @Prop() private tab!: ModalAccountDetailsTabCode;
  @Prop() private billingAccount!: BillingAccount;
  @Prop() private isMonthlyPayment!: boolean;
  @Prop() private balance!: number;
  @Prop() private accountInfo!: BillingAccountInformation;
  innerEventBus: Vue = new Vue();
  currentTabId: TabId = "_tabCustomerDetails";
  innerTabIsLoading: boolean = false;
  currentCCStep: CreditCardFormStep = 1;
  activeCCVirtualStep: CreditCardFormVirtualStep = "none";
  debtDisclaimerAgreedByCustomer: boolean = false;
  tabViewHeight: number = 500;

  get shouldDisplayModalBackBtn() {
    return this.currentTabId === "_tabCreditCard" && this.currentCCStep > 1;
  }

  getNextConfirmAction(): ModalBillingDetailsAction {
    switch (this.currentTabId) {
      case "_tabCustomerDetails":
        return () => {
          this.nativeClick("payment-object-wrapper-update-personaljpc-update-personal-btn-submit");
        };
      case "_tabBillingDetails":
        return () => {
          this.nativeClick("payment-object-wrapper-update-billingjpc-update-billing-btn-submit");
        };
      case "_tabCreditCard":
        return this.getNextConfirmActionForCC();
    }
    return null;
  }

  get canSubmitCcFormToZuora() {
    return this.balance >= 0 || this.debtDisclaimerAgreedByCustomer;
  }

  getNextConfirmActionForCC(): ModalBillingDetailsAction {
    if (this.currentCCStep === 1) {
      return () => {
        this.nativeClick("payment-object-wrapper-update-ccjpc-credit-card-btn-edit");
      };
    }

    if (this.canSubmitCcFormToZuora) {
      return () => {
        this.nativeClick("payment-object-wrapper-update-ccjpc-btn-submit");
      };
    }

    return this.getNextConfirmActionForVirtualCCStep();
  }

  getNextConfirmActionForVirtualCCStep(): ModalBillingDetailsAction {
    if (this.activeCCVirtualStep === "none") {
      return () => {
        this.activeCCVirtualStep = "debtDisclaimer";
        this.innerEventBus.$emit("displayDebtDisclaimerView");
      };
    } else if (this.activeCCVirtualStep === "debtDisclaimer") {
      return () => {
        this.activeCCVirtualStep = "none";
        this.debtDisclaimerAgreedByCustomer = true;
        this.innerEventBus.$emit("hideDebtDisclaimerView");
        this.nativeClick("payment-object-wrapper-update-ccjpc-btn-submit");
      };
    }

    return null;
  }

  getBackConfirmAction(): ModalBillingDetailsAction {
    switch (this.currentTabId) {
      case "_tabCustomerDetails":
      case "_tabBillingDetails":
        return () => {
          this.nativeClick("payment-object-wrapper-update-ccjpc-btn-cancel");
        };

      case "_tabCreditCard":
        return this.getBackConfirmActionForCC();
    }
    return null;
  }
  getBackConfirmActionForCC(): ModalBillingDetailsAction {
    if (this.activeCCVirtualStep === "none") {
      return () => {
        this.nativeClick("payment-object-wrapper-update-ccjpc-btn-cancel");
      };
    } else if (this.activeCCVirtualStep === "debtDisclaimer") {
      return () => {
        this.activeCCVirtualStep = "none";
        this.innerEventBus.$emit("hideDebtDisclaimerView");
      };
    }

    return null;
  }

  get initialActiveTabId() {
    switch (this.tab) {
      case "customer_details":
        return "_tabCustomerDetails";
      case "billing_details":
        return "_tabBillingDetails";
      case "cc":
        return "_tabCreditCard";
    }
    return "";
  }
  get loadingMaskProps(): LoadingMaskProps {
    return {
      loading: this.isLoading,
    };
  }

  get confirmBtnText() {
    switch (this.currentTabId) {
      case "_tabCustomerDetails":
      case "_tabBillingDetails":
        return "Update";
      case "_tabCreditCard":
        return this.currentCCStep === 2 ? this.confirmBtnTextForVirtalCCStep : "Edit";
    }
    return "";
  }

  get confirmBtnTextForVirtalCCStep() {
    switch (this.activeCCVirtualStep) {
      case "none":
        return "Update";
      case "debtDisclaimer":
        return "Agree";
    }
    return "";
  }

  get currentSendGtmEventTitle() {
    const actionName = this.confirmBtnText.toLowerCase();
    let tabName = "";
    switch (this.currentTabId) {
      case "_tabCustomerDetails":
        tabName = "customer-details";
        break;
      case "_tabBillingDetails":
        tabName = "billing-details";
        break;
      case "_tabCreditCard":
        tabName = "credit-card-details";
        break;
    }
    return actionName + "-" + tabName;
  }

  get tabHeaderStyles() {
    return {
      paddingTop: "10px",
      paddingLeft: "20px",
      boxShadow: "inset 0px 3px 8px -5px rgba(0,0,0,0.2)",
    };
  }

  get tabContentWrapperStyles() {
    return {
      height: this.tabViewHeight + "px",
    };
  }

  get tabStyles() {
    return {
      width: "160px",
    };
  }

  get isLoading(): boolean {
    return !this.tab;
  }

  onTabShow(id: TabId) {
    this.$jfUsers.redirectIfNotSync();
    this.currentTabId = id;
  }

  onConfirm() {
    this.$jfUsers.redirectIfNotSync();

    const confirmAction: ModalBillingDetailsAction = this.getNextConfirmAction();

    if (!!confirmAction) {
      confirmAction();
      return;
    }

    this.$log.error("Any confirm action defined!");
  }

  onBack() {
    this.$jfUsers.redirectIfNotSync();

    const backAction: ModalBillingDetailsAction = this.getBackConfirmAction();

    if (!!backAction) {
      backAction();
      return;
    }

    this.$log.error("Any back action defined!");
  }

  nativeClick(htmlElementId: string) {
    this.$jfNotification.clearAll();
    const htmlEl = document.getElementById(htmlElementId);
    !!htmlEl && htmlEl.click();
  }

  onInnerTabLoadingChange(innerTabIsLoading: boolean) {
    this.innerTabIsLoading = innerTabIsLoading;
  }

  onCCStepChange(step: CreditCardFormStep) {
    this.currentCCStep = step;
  }

  dismiss() {
    this.$jfModal.dismiss();
  }

  mounted() {
    // should be called in each custom modal in his mounted() method (it will trigger resizes and other global stuff).
    // Called automatically by Essentials in JS but doesn't work in TS components so we should trigger it manually.
    this.$jfModal.childComponentIsSet();
    this.resizeTabsView();
  }
  resizeTabsView() {
    // fix the inner vertical scrollbar of the tabs to be always at the good size.
    // the tabHeader element height is available only on the next tick.
    setTimeout(() => {
      const modalBody = (this.$refs.modalBody as Vue).$el;
      const modalBodyHeight = modalBody.clientHeight;
      const tabsHeaderWrapper = modalBody.querySelector(".tabs-header-wrapper") as HTMLDivElement;
      const tabsHeaderWrapperHeight = tabsHeaderWrapper.clientHeight;
      this.tabViewHeight = modalBodyHeight - tabsHeaderWrapperHeight;
    }, 0);
  }
}
