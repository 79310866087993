
import LoadingMask from "@/components/common/LoadingMask.vue";
import PulseSpinner from "@/components/spinners/PulseSpinner.vue";
import ModalBillingDetails from "@/components/views/billingAccounts/modals/ModalBillingDetails.vue";
import ModalInvoicesHistory from "@/components/views/billingAccounts/modals/ModalInvoicesHistory.vue";
import { LoadingMaskProps } from "@/types/loadingMask";
import { BillingAccount, ModalAccountDetailsTabCode, FlatSubscription } from "@/types/localtypes";
import { PulseSpinnerProperties } from "@/types/spinners";
import { BillingAccountInformation, InvoiceDTO, SubscriptionDTO } from "@jfrog-ba/myjfrog-common";
import { Component, Inject, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
  name: "BillingAccountDetails",
  components: { LoadingMask, PulseSpinner },
})
export default class BillingAccountDetails extends Vue {
  @Inject() readonly globalBus!: Vue;
  @Prop() private billingAccount!: BillingAccount;
  @Prop() private subscriptions!: SubscriptionDTO[];
  @Prop() private isPrepaidPayment!: boolean;
  @Prop() private isMonthlyPayment!: boolean;
  @Prop() private eventBus!: Vue;
  @Watch("billingAccount")
  onBillingAccountChange() {
    this.fetchDetails();
  }
  invoices: InvoiceDTO[] | null = null;
  accountInfo: BillingAccountInformation | null = null;
  isLoading: boolean = true;
  estimatedBalance: string = "";
  balance: number = 0;

  detailSpinnerProps: PulseSpinnerProperties = {
    size: "xxs",
  };

  getMainServerName(subscription: SubscriptionDTO) {
    return this.$jfSubscriptions.extractMainServer(subscription.jpus, subscription.paymentType).serverName;
  }

  get nextBalanceDisclaimer() {
    return this.$jfMessages.billing_disclaimer_next_expected_balance;
  }

  get hideEditAccount() {
    const subscriptionDTOS = this.billingAccount && this.billingAccount.subscriptions;
    return subscriptionDTOS && subscriptionDTOS.length > 0 && subscriptionDTOS[0].meta.isFree;
  }

  get buttonClasses() {
    let classes = [];
    if (this.isMobile) {
      classes.push("btn-xs");
    }
    return classes;
  }
  get loadingMaskProps(): LoadingMaskProps {
    return {
      loading: this.isLoading,
    };
  }

  get accountName() {
    return this.accountInfo ? this.accountInfo.name : "Error...";
  }

  get billingPaymentType() {
    if (this.billingAccount.subscriptions) {
      const meta = this.billingAccount.subscriptions[0].meta;
      if (meta.isMonthlyPayment) {
        return "Monthly";
      }
      if (meta.isPrepaidPayment) {
        return "Prepaid";
      }
      return this.billingAccount.subscriptions[0].paymentType;
    }
    return "Error...";
  }

  get paymentProvider() {
    return this.billingAccount.subscriptions[0].paymentProvider;
  }

  get firstOfNextMonth() {
    let endOfMonth = this.$dateUtils.endOf(new Date(), "month");
    let firstOfNextMonth = this.$dateUtils.add(endOfMonth, 1, "second");
    return this.$dateUtils.format(firstOfNextMonth, "YYYY-MM-DD");
  }

  async fetchDetails() {
    this.isLoading = true;
    const billingAccountNumberFetched = this.billingAccount.paymentAccountNumber;
    try {
      const billingAccountInfoResponse = await this.$jfBillingAccounts.getBillingAccountInfo(
        billingAccountNumberFetched,
      );
      if (billingAccountNumberFetched === this.billingAccount.paymentAccountNumber) {
        this.accountInfo = billingAccountInfoResponse.account;
        this.estimatedBalance = "$ " + billingAccountInfoResponse.balance;
        this.balance = parseFloat(billingAccountInfoResponse.balance.replaceAll(",", ""));
        this.isLoading = false;
      }
    } catch (error) {
      this.$log.error(error);
      this.accountInfo = null;
      this.isLoading = false;
      this.notifyError(this.$jfMessages.billing_fetch_details_failed);
    }
  }

  notifyError(errorMessage: string) {
    this.globalBus.$emit("notifyError", errorMessage);
  }

  get isMobile() {
    return this.$mq === "mobile";
  }

  showHistoryModal() {
    this.$jfModal.showCustom(
      ModalInvoicesHistory,
      "xxl",
      {
        invoices: this.invoices,
        paymentAccountNumber: this.billingAccount.paymentAccountNumber,
        subscriptions: this.subscriptions,
      },
      {
        title: "Billing History",
        forceDisplayCancelIcon: this.isMobile,
        headerBorder: false,
        footerBorder: false,
        clickShouldClose: false,
        shakeIfCantClose: false,
      },
    );
  }
  showAccountDetailsModal(tab: ModalAccountDetailsTabCode = "customer_details") {
    this.$jfUsers.redirectIfNotSync();
    this.$jfModal.showCustom(
      ModalBillingDetails,
      "md",
      {
        tab,
        billingAccount: this.billingAccount,
        isMonthlyPayment: this.isMonthlyPayment,
        balance: this.balance,
        accountInfo: this.accountInfo,
      },
      {
        title: "Edit Account",
        forceDisplayCancelIcon: this.isMobile,
        headerBorder: false,
        footerBorder: false,
        bodyPaddingTop: false,
        bodyPaddingBottom: false,
        bodyPaddingLeft: false,
        bodyPaddingRight: false,
        clickShouldClose: false,
        shakeIfCantClose: false,
      },
    );
  }

  onShouldShowInvocieHistory() {
    this.showHistoryModal();
  }

  shouldShowCreditCardDetails() {
    this.showAccountDetailsModal("cc");
  }

  onLoadingInvoices() {
    this.invoices = null;
  }
  onNewInvoicesLoaded(invoices: InvoiceDTO[], paymentAccountNumber: BillingAccount["paymentAccountNumber"]) {
    if (this.billingAccount && paymentAccountNumber === this.billingAccount.paymentAccountNumber) {
      this.invoices = invoices;
    }
  }

  busEvents: { [key: string]: (...params: any[]) => any } = {
    loadingInvoices: this.onLoadingInvoices,
    newInvoicesLoaded: this.onNewInvoicesLoaded,
    shouldShowInvocieHistory: this.onShouldShowInvocieHistory,
    shouldShowCreditCardDetails: this.shouldShowCreditCardDetails,
  };

  created() {
    for (let busEventsKey in this.busEvents) {
      this.eventBus.$on(busEventsKey, this.busEvents[busEventsKey]);
    }
  }

  beforeDestroy() {
    for (let busEventsKey in this.busEvents) {
      this.eventBus.$off(busEventsKey, this.busEvents[busEventsKey]);
    }
  }

  mounted() {
    if (this.billingAccount) {
      this.fetchDetails();
    }
  }
}
