
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import BarChart from "@/components/charts/BarChart.vue";
import graphOptions from "./previousInvoicesGraphOptions";
import { InvoiceDTO } from "@jfrog-ba/myjfrog-common";
import { ChartsProps } from "@/types/charts";

@Component({
  name: "PreviousInvoicesGraph",
  components: { BarChart },
})
export default class PreviousInvoicesGraph extends Vue {
  @Prop() private invoices!: InvoiceDTO[];
  @Watch("invoices")
  onInvoicesChange(invoices: InvoiceDTO[]) {
    this.buildChartData();
  }

  get hasInvoices() {
    return this.invoices !== null && this.invoices.length;
  }

  invoicesChartData = {};
  get invoicesChartProps(): ChartsProps {
    return {
      customOptions: graphOptions,
      data: this.invoicesChartData,
    };
  }

  calculateBarPercent(): number {
    if (this.hasInvoices) {
      const nbBars = this.invoices.length;
      if (nbBars >= 30) return 1;
      if (nbBars >= 25) return 0.9;
      if (nbBars >= 20) return 0.8;
      if (nbBars >= 15) return 0.7;
      if (nbBars >= 10) return 0.5;
      if (nbBars >= 5) return 0.3;
      if (nbBars >= 3) return 0.1;
      return 0.05;
    }
    return 0.05;
  }

  // customInvoices() {
  //   let maxRand = 20;
  //   let randomInvoiceNumber = Math.floor(Math.random() * maxRand + 1);
  //   // randomInvoiceNumber = 12;
  //   this.$log.info("randomInvoiceNumber : ", randomInvoiceNumber);
  //   let fakeInvoices = [];
  //   for (let i = 0; i < randomInvoiceNumber; i++) {
  //     const date = 1556658000000 - 10000000000 * i;
  //     fakeInvoices.push({
  //       dataTransferUsage: 3.195849609375,
  //       dataTransferUsageUnit: "GB",
  //       invoiceDate: date,
  //       invoiceFileKey: "2c92c08b6ade6cf2016adf05d6920ab8",
  //       invoiceNumber: "INV00067007",
  //       invoiceTotalAmount: 2008,
  //       storageUsage: 2.7119140625,
  //       storageUsageUnit: "GB",
  //     });
  //   }

  //   return fakeInvoices;
  // }

  buildChartData() {
    let labelsArray: string[] = [];
    let valuesArray: string[] = [];
    if (this.hasInvoices) {
      labelsArray = this.invoices.map(invoice => this.$dateUtils.format(invoice.invoiceDate, "MMM YYYY"));
      valuesArray = this.invoices.map(invoice => this.$utils.round(invoice.invoiceTotalAmount, 2));
    }
    this.invoicesChartData = {
      labels: labelsArray,
      datasets: [
        {
          id: "Month",
          label: "Total Charge",
          backgroundColor: "rgba(119,49,199,1)",
          data: valuesArray,
          barPercentage: this.calculateBarPercent(),
        },
      ],
    };
  }

  mounted() {
    this.buildChartData();
  }
}
