
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { BillingAccount } from "@/types/localtypes";

@Component({
  name: "BillingAccountsFilter",
  components: {},
})
export default class BillingAccountsFilter extends Vue {
  @Prop() private billingAccounts!: BillingAccount[];
  @Prop() private onChange!: (billingAccount: BillingAccount) => any;

  selectedAccount = "";

  get filterProps() {
    return {
      options: this.accountsInputs,
      clearable: false,
      searchable: true,
      filterable: true,
    };
  }
  get wrapperClasses() {
    let classes = [this.$mq];
    return classes;
  }

  get accountsInputs() {
    return this.billingAccounts.map(account => ({
      label: account.paymentAccountNumber,
      code: account.paymentAccountNumber,
      servers: this.extractServerNames(account),
    }));
  }

  extractServerNames(account: BillingAccount) {
    return account.subscriptions
      .map(
        subscription => this.$jfSubscriptions.extractMainServer(subscription.jpus, subscription.paymentType).serverName,
      )
      .join(", ");
  }

  onAccountSelected(option: {
    label: BillingAccount["paymentAccountNumber"];
    code: BillingAccount["paymentAccountNumber"];
  }) {
    this.onChange(
      this.billingAccounts.find(account => account.paymentAccountNumber === option.label) as BillingAccount,
    );
  }

  mounted() {
    this.selectedAccount = this.billingAccounts[0].paymentAccountNumber;
  }
}
