
import LoadingMask from "@/components/common/LoadingMask.vue";
import SimpleAlert from "@/components/common/SimpleAlert.vue";
import { Alert } from "@/types/layout/contentNode";
import { LoadingMaskProps } from "@/types/loadingMask";
import { BillingAccount, CreditCardFormStep } from "@/types/localtypes";
import { BillingAccountInformation, JPCInstance } from "@jfrog-ba/myjfrog-common";
import { Component, Inject, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "TabCCDetails",
  components: { LoadingMask, SimpleAlert },
})
export default class TabCCDetails extends Vue {
  @Inject() readonly globalBus!: Vue;
  @Prop() private billingAccount!: BillingAccount;
  @Prop() private balance!: number;
  @Prop() currentStep!: CreditCardFormStep;
  @Prop() private accountInfo!: BillingAccountInformation;
  @Prop() private innerEventBus!: Vue;
  paymentObjectWrapperId = "payment-object-wrapper-update-cc";
  isLoading: boolean = true;
  jpcLoaded = false;
  errorRecentlyReceived = false;
  displayingDebtDisclaimer = false;
  alertData: Alert = {
    message: `Important: Your JFrog account shows a debt balance of $${Math.abs(
      this.balance,
    )}. Entering the credit card details will automatically charge this due balance.`,
    type: "warn",
  };

  get loadingMaskProps(): LoadingMaskProps {
    return {
      loading: this.isLoading,
    };
  }

  get shouldDisplayDebtAlert() {
    return !this.displayingDebtDisclaimer && this.balance < 0;
  }

  setLoading(loading: boolean, shouldActivateButton: boolean = true) {
    this.isLoading = loading;
    if (shouldActivateButton) {
      this.$emit("onLoadingChange", loading);
    }
  }

  async mounted() {
    this.setLoading(true);
    this.createInnerEventsHandlers();
    this.setCurrentStep(1);
    // @ts-ignore
    if (window.JPC) {
      this.jpcLoaded = true;
    } else {
      try {
        const paymentScript = await this.$jfPayments.fetchPaymentsScripts();
        if (paymentScript) {
          eval(paymentScript);
          this.jpcLoaded = true;
        }
      } catch (e) {
        this.$log.error(e);
        this.notifyError(this.$jfMessages.jpay_loading_failed);
      }
    }
    if (this.jpcLoaded) {
      this.loadCCDetails();
    } else {
      this.setLoading(false);
    }
  }

  loadCCDetails() {
    // @ts-ignore
    const jpc: JPCInstance = new JPC(
      this.getJPCPrepopulate(),
      this.getJPCMessages(),
      this.getJPCOptions(),
      this.loadJPCCallbacks(),
    );
    jpc.loadCreditCardForm(this.billingAccount.paymentAccountNumber);
  }

  getJPCOptions(): JPCInstance["options"] {
    return {
      divJpcFormId: this.paymentObjectWrapperId,
      placeHoldersActive: false,
      appSessionId: this.$jfUsers.getSessionId(),
      creditCardIframeWidth: null,
      creditCardIframeHeight: 350,
    };
  }

  getJPCPrepopulate(): JPCInstance["prepopulate"] {
    return {};
  }

  getJPCMessages(): JPCInstance["messages"] {
    return { btn_editCreditCard: "Edit" };
  }

  setCurrentStep(step: CreditCardFormStep) {
    this.$emit("onCCStepChange", step);
  }

  get jpcLogErrorPrefix() {
    return `jpc-callback-update-cc [${this.billingAccount.paymentAccountNumber}] : `;
  }

  loadJPCCallbacks(): JPCInstance["callbacks"] {
    return {
      buyCustomerDetailsForm_hideOverlayFor3DS: () => {
        setTimeout(() => {
          this.setLoading(false, false);
        }, 3000);
      },
      creditCardForm_render(html) {
        const inputs = html.querySelectorAll("input");
        for (let i = 0; i < inputs.length; i++) {
          inputs[i].classList.add("form-control");
        }
        return html;
      },
      creditCardForm_submitSuccess: (data: {}) => {
        // this.$log.info("creditCardForm_submitSuccess", data);
        this.setLoading(true);
      },
      creditCardForm_updateDefaultCardSuccess: (data: {}) => {
        // this.$log.info("creditCardForm_updateDefaultCardSuccess", data);
        this.notifySuccess();
        this.setCurrentStep(1);
        this.setLoading(false);
      },
      creditCardForm_loadSuccess: () => {
        this.setLoading(false);
      },
      creditCardForm_loadError: (status: number, errorMessage: string) => {
        // this.$log.info(`creditCardForm_loadError`);
        const jpcErrorMessage = `${this.jpcLogErrorPrefix}Error : ${status} => ${errorMessage || "no message"}`;
        this.$log.error(jpcErrorMessage);
        this.setLoading(false);
        if (status === 401) {
          this.$jfUsers.logout(true);
          return;
        }
        this.notifyError(this.$jfMessages.jpay_credit_card_form_loading_failed);
      },
      creditCardForm_updateDefaultCardError: (status: number, errorMessage: string) => {
        // this.$log.info(`creditCardForm_updateDefaultCardError`);
        const jpcErrorMessage = `${this.jpcLogErrorPrefix}Error : ${status} => ${errorMessage || "no message"}`;
        this.$log.error(jpcErrorMessage);
        this.setLoading(false);
        if (status === 401) {
          this.$jfUsers.logout(true);
          return;
        }
        this.notifyError(errorMessage);
        this.setCurrentStep(1);
      },
      creditCardForm_submitError: (status: number, errorMessage: string) => {
        // this.$log.info("creditCardForm_submitError status=" + status + " / error = " + errorMessage);
        const jpcErrorMessage = `${this.jpcLogErrorPrefix}Error : ${status} => ${errorMessage || "no message"}`;
        this.$log.error(jpcErrorMessage);
        this.setLoading(false);
        this.notifyError(this.$jfMessages.extractPaymentsError(errorMessage));
      },
      creditCardForm_beforeEdit: () => {
        // this.$log.info("creditCardForm_beforeEdit");
        this.setLoading(true);
        return true;
      },
      creditCardForm_beforeChangePage: () => {
        // this.$log.info("creditCardForm_beforeChangePage");
        return true;
      },
      creditCardForm_afterChangePage: () => {
        // this.$log.info("creditCardForm_afterChangePage");
        this.setCurrentStep(2);
        this.setLoading(false);
        return true;
      },
      creditCardForm_beforePrev: () => {
        // this.$log.info("creditCardForm_beforePrev");
        this.setCurrentStep(1);
        return true;
      },
      creditCardForm_beforeSubmit: () => {
        // this.$log.info("creditCardForm_beforeSubmit");
        this.setLoading(true);
        return true;
      },
    };
  }

  notifySuccess() {
    this.$jfWebeyez.send({ goal_key: "update_credit_card_details", isSuccess: true });
    if (this.balance < 0) {
      this.notifySuccessWithBalance();
    } else {
      this.$jfNotification.success({ text: this.$jfMessages.billing_payments_credit_card_details_update_success });
    }
  }

  notifySuccessWithBalance() {
    this.$jfSubscriptions.debtPaymentFlow({ paymentAccountNumber: this.accountInfo.accountNumber }).then(() => {
      if (this.billingAccount.subscriptions[0].state === "ACTIVE") {
        this.$jfNotification.success({
          text: this.$jfMessages.billing_payments_debt_credit_card_details_update_success_active_account,
        });
      } else {
        this.$jfNotification.success({
          text: this.$jfMessages.billing_payments_debt_credit_card_details_update_success_blocked_account,
        });
      }
    });
  }

  notifyError(errorMessage: string) {
    this.globalBus.$emit("notifyError", errorMessage);
    this.$jfWebeyez.send({ goal_key: "update_credit_card_details", isSuccess: false, errorMessage: errorMessage });
  }

  displayDebtDisclaimerView() {
    this.displayingDebtDisclaimer = true;
  }

  hideDebtDisclaimerView() {
    this.displayingDebtDisclaimer = false;
  }

  createInnerEventsHandlers() {
    this.innerEventBus.$on("displayDebtDisclaimerView", this.displayDebtDisclaimerView);
    this.innerEventBus.$on("hideDebtDisclaimerView", this.hideDebtDisclaimerView);
  }
}
