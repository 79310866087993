
import { Component, Inject, Vue, Watch } from "vue-property-decorator";
import { Action } from "vuex-class";
import { InvoiceDTO, SubscriptionDTO } from "@jfrog-ba/myjfrog-common";
import { PageTitleProps } from "@/types/layout/pageTitle";
import { BillingAccount } from "@/types/localtypes";
import { ViewContext } from "@/types/services/app";
import { MenuConfiguration } from "@/types/services/menus";
import BillingAccountDetails from "@/components/views/billingAccounts/BillingAccountDetails.vue";
import BillingAccountLastPayment from "@/components/views/billingAccounts/BillingAccountLastPayment.vue";
import BillingAccountPreviousPayments from "@/components/views/billingAccounts/BillingAccountPreviousPayments.vue";
import BillingAccountsFilter from "@/components/views/billingAccounts/BillingAccountsFilter.vue";
import PageSection from "@/components/layout/PageSection.vue";
import PageTitle from "@/components/layout/PageTitle.vue";
import PageView from "@/components/layout/PageView.vue";

@Component({
  name: "BillingAccounts",
  components: {
    PageView,
    PageTitle,
    PageSection,
    BillingAccountDetails,
    BillingAccountLastPayment,
    BillingAccountPreviousPayments,
  },
})
export default class BillingAccounts extends Vue {
  @Inject() readonly globalBus!: Vue;
  @Action("setMenuConfiguration", { namespace: "application" })
  setMenuConfiguration!: (menuConfiguration: MenuConfiguration) => void;
  @Action("setViewContext", { namespace: "application" })
  setViewContext!: (viewContext: ViewContext) => void;
  subscriptions: SubscriptionDTO[] = [];
  billingAccounts: BillingAccount[] = [];
  selectedBillingAccount: BillingAccount | null = null;
  isLoading: boolean = true;
  invoices: InvoiceDTO[] = [];
  eventBus: Vue = new Vue();

  @Watch("subscriptions")
  async onSubscriptionsChanged() {
    this.setMenuConfiguration(await this.defineMenuConfiguration());
  }
  async defineMenuConfiguration(): Promise<MenuConfiguration> {
    this.globalBus.$emit("onMenuConfigurationChange");
    if (this.subscriptions) {
      return await this.$jfMenus.defineNotContextualMenuConfiguration(
        this.subscriptions,
        (menuId, serverName, tooltipContent, tooltipHasSubmitAction, meta) => {
          this.globalBus.$emit(
            "onMenuTooltipClick",
            menuId,
            serverName,
            tooltipContent,
            tooltipHasSubmitAction,
            this.$jfSubscriptions.getSubscriptionRequestReason(menuId, meta),
          );
        },
      );
    }
    return {};
  }

  defineViewContext(): ViewContext {
    return this.subscriptions && this.selectedBillingAccount
      ? {
          subscriptionMetas: this.subscriptions.map(s => s.meta),
          billingAccounts: this.billingAccounts,
          currentBillingAccount: this.selectedBillingAccount,
        }
      : {};
  }

  get mpBillingWrapperStyle() {
    // mp billing section should be height 100% so we put his height to his parent's height
    const viewContentWrapper = document.getElementsByClassName("view-content-wrapper")[0] as HTMLDivElement;
    const wrapperHeight = viewContentWrapper.clientHeight;
    return {
      height: wrapperHeight + "px",
    };
  }

  get awsManagementDisclaimer() {
    return this.$jfMessages.billing_disclaimer_aws_management;
  }
  get gcpManagementDisclaimer() {
    return this.$jfMessages.billing_disclaimer_gcp_management;
  }
  get azureManagementDisclaimer() {
    return this.$jfMessages.billing_disclaimer_azure_management;
  }

  get firstSubscription() {
    return !!this.selectedBillingAccount && this.selectedBillingAccount.subscriptions[0];
  }

  get isPrepaidPayment() {
    return this.firstSubscription && this.firstSubscription.meta.isPrepaidPayment;
  }
  get isMonthlyPayment() {
    return this.firstSubscription && this.firstSubscription.meta.isMonthlyPayment;
  }
  get isAzureMPPayment() {
    return this.firstSubscription && this.firstSubscription.meta.isAzureMP;
  }

  get hasMultiBillingAccounts() {
    return this.billingAccounts && this.billingAccounts.length > 1;
  }

  get pageTitleProps(): PageTitleProps {
    let title = "";
    let leftOptionComponentConfig: PageTitleProps["leftOptionComponentConfig"] = undefined;
    if (this.isLoading) {
      title = "Loading...";
    } else {
      if (this.hasMultiBillingAccounts) {
        leftOptionComponentConfig = {
          component: BillingAccountsFilter,
          props: {
            billingAccounts: this.billingAccounts,
            onChange: this.onBillingAccountChange,
          },
        };
      } else {
        title = this.isAzureMPPayment
          ? `Microsoft Azure SaaS Subscription`
          : `Account Number: ${!!this.selectedBillingAccount && this.selectedBillingAccount.paymentAccountNumber}`;
      }
    }
    return {
      title,
      leftOptionComponentConfig,
    };
  }

  get lastInvoice() {
    return this.sortedInvoices ? this.sortedInvoices[this.sortedInvoices.length - 1] : null;
  }

  get displayingAWSBillingAccount() {
    return this.firstSubscription && this.firstSubscription.meta.isAWSMP;
  }
  get displayingGCPBillingAccount() {
    return this.firstSubscription && this.firstSubscription.meta.isGCPMP;
  }
  get displayingAzureBillingAccount() {
    return this.firstSubscription && this.firstSubscription.meta.isAzureMP;
  }

  get displayingMPBillingAccount() {
    return this.displayingAWSBillingAccount || this.displayingGCPBillingAccount || this.displayingAzureBillingAccount;
  }

  async fetchBillingAccounts() {
    try {
      this.subscriptions = await this.$jfSubscriptions.getSubscriptions();
      this.billingAccounts = this.$jfBillingAccounts.createFromSubscriptions(this.subscriptions);
      this.selectedBillingAccount = this.billingAccounts[0];
      this.isLoading = false;
      this.fetchInvoices();
      this.setViewContext(this.defineViewContext());
    } catch (error) {
      this.$log.error(error);
      this.isLoading = false;
      this.$jfNotification.error({
        text: this.$jfMessages.billing_list_fetch_failed,
      });
    }
  }

  async fetchInvoices() {
    this.eventBus.$emit("loadingInvoices");
    this.invoices = [];
    const accountNumberOfInvoices = (this.selectedBillingAccount as BillingAccount).paymentAccountNumber;
    try {
      if (this.selectedBillingAccount && !this.displayingMPBillingAccount) {
        this.invoices = await this.$jfBillingAccounts.getInvoices(this.selectedBillingAccount.paymentAccountNumber, 12);
      }
      this.eventBus.$emit("newInvoicesLoaded", this.sortedInvoices, accountNumberOfInvoices);
    } catch (e) {
      this.$log.error(e);
      this.eventBus.$emit("errorWhileLoadingInvoices", accountNumberOfInvoices);
    }
  }

  get sortedInvoices() {
    if (this.invoices.length) {
      return this.invoices.sort((a: InvoiceDTO, b: InvoiceDTO) => {
        if (a.invoiceDate === b.invoiceDate) {
          return 0;
        }
        return a.invoiceDate < b.invoiceDate ? -1 : 1;
      });
    }
    return [];
  }

  onBillingAccountChange(billingAccount: BillingAccount) {
    this.selectedBillingAccount = billingAccount;
    this.fetchInvoices();
    this.setViewContext(this.defineViewContext());
  }

  goToGCPSettings() {
    try {
      const gcpUrl = getGcpUrl(this.firstSubscription);
      window.open(gcpUrl, "_blank");
    } catch (error) {
      this.$log.error(error);
    }

    function getGcpUrl(firstSubscription: false | SubscriptionDTO) {
      if (!firstSubscription) {
        throw new Error("firstSubscription is not defined");
      }

      const firstSubscriptionMeta = firstSubscription.meta;
      let gcpUrl = "https://console.cloud.google.com/marketplace/details/jfrog/";
      if (firstSubscriptionMeta.isEnterprisePlus) {
        gcpUrl += "jfrog-ent-plus-saas";
      } else if (firstSubscriptionMeta.isCloudProX) {
        gcpUrl += "jfrog-isaas";
      } else if (firstSubscriptionMeta.isProTeam) {
        gcpUrl += "jfrog-pro-team-saas";
      } else if (firstSubscriptionMeta.isEnterprise || firstSubscriptionMeta.isEnterpriseX) {
        gcpUrl += "jfrog-ent-saas";
      } else {
        throw new Error(`${firstSubscription.paymentSubscription} is not defined for GCP settings URL`);
      }

      return gcpUrl;
    }
  }
  goToAzureSettings() {
    window.open("https://portal.azure.com/#blade/Microsoft_Azure_Billing/BillingMenuBlade/Overview", "_blank");
  }
  goToAWSSettings() {
    window.open("https://aws.amazon.com/marketplace/library?productType=saas", "_blank");
  }
  goToAWSBill() {
    window.open("https://console.aws.amazon.com/billing/home?source=awsmktplace#/", "_blank");
  }

  async mounted() {
    this.$jfModal.closeAll();
    this.fetchBillingAccounts();
  }
}
